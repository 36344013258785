<template>
  <div id="inspectionDetails">
    <CSBreadcrumb/>
    <div class="filter-panel">
      <CSSelect
        style="margin-right: 0;"
      >
        <el-date-picker
            type="datetime"
            placeholder="请选择开始时间"
            v-model="queryInfo.startDate"
            style="width: 185px"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            prefix-icon="el-icon-time"
            :editable="false"
        >
        </el-date-picker>
      </CSSelect>
      <span class="inline-gray"></span>
      <CSSelect>
        <el-date-picker
            type="datetime"
            placeholder="请选择开始时间"
            v-model="queryInfo.endDate"
            style="width: 185px"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            prefix-icon="el-icon-time"
            :editable="false"
        >
        </el-date-picker>
      </CSSelect>
      <CSSelect>
        <select
          style="padding-left: 6px;"
          v-model="queryInfo.userId"
        >
          <option value="">巡检人不限</option>
          <option
            v-for="staff in staffList"
            :key="staff.id"
            :value="staff.id"
          >{{staff.name}}</option>
        </select>
      </CSSelect>
      <button class="btn btn-primary" @click="getInspectLog">查询</button>
    </div>
    <div class="result-panel">
        <CSTable :thead-top="filterHeight">
            <template v-slot:thead>
                <tr>
                    <th>巡检时间</th>
                    <th>签到时间</th>
                    <th>检查项</th>
                    <th>巡检人</th>
                </tr>
            </template>
            <template v-slot:tbody>
                <tr
                    v-for="InspectLog in InspectLogList"
                    :key="InspectLog.pointLogId"

                >
                    <td>{{InspectLog.inspectionTime}}</td>
                    <td>{{InspectLog.signTime || '-'}}</td>
                    <td>
              <span class="allow-click"
                    @click="toCheckItems(InspectLog.pointLogId)"
              >
                {{InspectLog.checkItemNum}}
              </span>
                    </td>
                    <td>{{InspectLog.userName}}</td>
                </tr>
            </template>
        </CSTable>
    </div>
  </div>
</template>

<script>
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import CSSelect from "@/components/common/CSSelect";
import dayjs from "dayjs";

import {
  queryDepartmentStaffUrl,
  adminQueryInspectLogUrl,
} from "@/requestUrl"
import CSTable from "@/components/common/CSTable";

export default {
    components: {
        CSTable,
      CSBreadcrumb,
      CSSelect
    },
    props: {
      id: Number,
    },
    data() {
      return {
          filterHeight: 0,
        // 员工列表
        staffList: [],
        // 查询信息
        queryInfo: {
          endDate: dayjs().format("YYYY-MM-DD") + " 23:59",
          locationId: null,
          pageSize: 50,
          regionCode: this.$vc.getCurrentRegion().code,
          startDate: dayjs().subtract(7, "day").format("YYYY-MM-DD") + " 00:00",
          userId: "",
        },
        // 巡检记录列表
        InspectLogList: [],
      }
    },
    created() {
      this.getStaff();
      this.getInspectLog();
    },
    mounted() {
        this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        window.addEventListener('resize', () => {
            this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        })
    },
    methods: {
      // 获取员工列表
      async getStaff() {
        let res = await this.$fly.post(queryDepartmentStaffUrl, {
          departmentCode: "",
          isOnTheJob: true,
          jobLevel: "",
          regionCode: this.$vc.getCurrentRegion().code,
        })
        if (res.code != 0) {
          return;
        }
        this.staffList = res.data;
      },
      // 获取巡检记录
      async getInspectLog() {
        let res = await this.$fly.post(adminQueryInspectLogUrl, {
          ...this.queryInfo,
          locationId: this.id,
          regionCode: this.$vc.getCurrentRegion().code,
        })
        if (res.code != 0) {
          return;
        }
        console.log(res);
        this.InspectLogList = res.data || [];
      },
      // 去往检查项(四级页面了emmmmm)
      toCheckItems(id) {
        this.$router.push({name: "checkItems", params: {id}});
      }
    },
  }
</script>

<style lang="stylus" scoped>

</style>
